import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCoffee,
  faArrowRight,
  faBars,
  faTimes,
  faPaperPlane,
  faLink,
} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCoffee, faArrowRight, faBars, faPaperPlane, faTimes, faLink)
