import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import '../utils/fontawesome'
import mq from '../utils/media-queries'
import { ThemeProvider } from 'emotion-theming'
import theme from '../utils/theme'
import Hero from '../components/hero'
import bgImage from '../images/hero_bg@2x.jpg'
import bgImageLarge from '../images/hero_bg_lg.jpg'
import PostSummary from '../components/post-summary'
import Container from '../components/container'
import Header from '../components/header'
import Footer from '../components/footer'
import Modal from '../components/modal'
import SayHelloForm from '../forms/say-hello-form'

const PageWrap = styled.div`
  position: ${props => (props.modalOpen ? 'fixed' : 'static')};
`

const Summaries = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1.75rem;
  grid-row-gap: 1.75rem;
  margin: 0 0 4rem 0;
  ${mq[1]} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq[2]} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export default class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
    }
  }
  openModal = () => {
    this.setState({
      modalOpen: true,
    })
  }
  closeModal = () => {
    this.setState({
      modalOpen: false,
    })
  }
  render() {
    const latestPosts = this.props.data.allMarkdownRemark.edges
    return (
      <ThemeProvider theme={theme}>
        <PageWrap modalOpen={this.state.modalOpen}>
          <Header light />
          <Hero
            flexJustify={'center'}
            flexAlign={'center'}
            color={'white'}
            textAlign={'center'}
            heading={"Hi, I'm Brock"}
            subhead={'How can I help you?'}
            openModal={this.openModal}
            bgImage={bgImage}
            bgImageLarge={bgImageLarge}
          />
          {/* Post summaries section */}
          <section
            id="latest-posts"
            css={css`
              padding-top: 72px;
            `}
          >
            <Container>
              <h3
                css={css`
                  text-align: center;
                  position: relative;
                  left: 50%;
                  transform: translateX(-50%);
                  margin: 0 0 40px 0;
                  border-radius: ${theme.radius};
                `}
              >
                Latest Posts
              </h3>
              <Summaries>
                {latestPosts.slice(0, 3).map(({ node }) => {
                  return (
                    <PostSummary
                      title={node.frontmatter.title}
                      content={node.excerpt}
                      link={node.fields.slug}
                      key={node.id}
                    />
                  )
                })}
              </Summaries>
            </Container>
          </section>
          <Footer />
          <Modal
            modalOpen={this.state.modalOpen}
            closeModal={this.closeModal}
            width={'100vh'}
            heading={'Say Hello 👋'}
          >
            <SayHelloForm closeModal={this.closeModal} />
          </Modal>
        </PageWrap>
      </ThemeProvider>
    )
  }
}

// get posts
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { draft: { eq: false } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
