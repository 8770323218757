import React, { Component } from 'react'
import { css } from '@emotion/core'
import Button from './button'
import mq from '../utils/media-queries'

export default class Hero extends Component {
  render() {
    return (
        <div
          css={css`
            width: 100vw;
            height: 100vh;
            background: black url(${this.props.bgImage}) no-repeat center / cover;
            ${mq[2]} {
              background-image: url(${this.props.bgImageLarge});
            }
            color: ${this.props.color};
            text-align: ${this.props.textAlign};
            padding: 2rem;
            display: flex;
            flex-flow: row wrap;
            justify-content: ${this.props.flexJustify};
            align-content: ${this.props.flexAlign};
            position: relative;
          `}
        >
          <h1
            css={css`
              position: relative;
              z-index: 1;
            `}
          >
            {this.props.heading}
          </h1>
          <p
            css={css`
              width: 100%;
              position: relative;
              z-index: 1;
            `}
          >
            {this.props.subhead}
          </p>
          <Button
            zIndex={1}
            icon={this.props.buttonIcon}
            onClick={this.props.openModal}
          >
            Say Hello
          </Button>
        </div>
    )
  }
}
