import React, { Component } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mq from '../utils/media-queries'

const ModalOverlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${props => (props.modalOpen ? '9999' : '-9999')};
  opacity: ${props => (props.modalOpen ? '1' : '0')};
  transition: all ease 300ms;
`
const ModalWrap = styled.div`
  background: ${props => props.theme.white};
  transform: ${props =>
    props.modalOpen ? 'translateY(0)' : 'translateY(200px)'};
  width: ${props => (props.width ? props.width : '100vw')};
  height: ${props => (props.height ? props.height : '100vh')};
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  transition: all ease 300ms;
  padding: 2rem;
  ${mq[0]} {
    width: auto;
    height: auto;
    padding: 6rem 2rem 2rem 2rem;
    border-radius: ${props => props.theme.radius};
  }
`
const CloseButton = styled.a`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  font-size: 1.5rem;
`
const ModalHeading = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin: auto auto 3.5rem auto;
`

export default class Modal extends Component {
  render() {
    return (
      <ModalOverlay modalOpen={this.props.modalOpen}>
        <ModalWrap modalOpen={this.props.modalOpen}>
          <ModalHeading>{this.props.heading}</ModalHeading>
          {this.props.children}
          <CloseButton>
            <FontAwesomeIcon icon={'times'} onClick={this.props.closeModal} />
          </CloseButton>
        </ModalWrap>
      </ModalOverlay>
    )
  }
}
